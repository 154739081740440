import axiosIns from "@/libs/axios";

export default {
  namespaced: true,
  state: {
    isLoggedIn: false,
    user: null,
    navMenuItems: [],
    assignmentData: null,
    lecturetData: null,
    assignmentChildren: [],
    lectureChildren: [],
  },
  getters: {
    generateNavMenuItems: (state) => {
      return state.navMenuItems.map(item => ({
        title: item.title,
        route: item.route,
        icon: item.icon
      }));
    },
    getLoggedInUser: (state) => {
      return state.user;
    },
    hasPermission: (state) => (name) => {
      if (state.user) {
        return state.user.permissions.hasOwnProperty(name);
      }
      return false;
    },
    hasRole: (state) => (name) => {
      if (state.user.role_data.code_name === name) {
        return true;
      }
      return false;
    },
    isLoggedIn: (state) => state.isLoggedIn,
    getNavMenuItems: (state) => state.navMenuItems,
    getAssignmentData: state => state.assignmentData,
    getLectureData: state => state.lectureData,
    getAssignmentChildren: (state) => state.assignmentChildren,
    getLectureChildren: (state) => state.lectureChildren,
  },
  mutations: {
    setAssignmentData(state, assignmentData) {
      state.assignmentData = assignmentData;
    },
    clearAssignmentData(state) {
      state.assignmentData = null;
    },
    setLectureData(state, lectureData) {
      // state.lectureData = lectureData;
      state.lectureData = { ...lectureData };
    },
    clearLectureData(state) {
      state.lectureData = null;

    },
    login(state, data) {
      state.isLoggedIn = true;
      state.user = data.user;
    },
    logout(state) {
      state.isLoggedIn = false;
      state.user = null;
    },
    setNavMenuItems(state, menuItems) {
      state.navMenuItems = menuItems;
    },
    setAssignmentChildren(state, assignmentChildren) {      
      state.assignmentChildren = assignmentChildren;      
    },
    setLectureChildren(state, lectureChildren) {      
      state.lectureChildren = lectureChildren;      
    },  
  },
  actions: {
    async login({ commit, dispatch, state }, payload) {
      try {
        const res = await axiosIns.post("login", payload);
        if (res.status === 200) {
          commit("login", res.data);
          await dispatch("fetchAssignmentSubjects");
          const navMenuItems = await dispatch("generateNavMenuItems");
          commit("setNavMenuItems", navMenuItems);
        }
        return res;
      } catch (error){
        console.log("Error", error);
        throw error;
      }
    },
    async logout({ commit }) {
      const res = await axiosIns.post("logout");
      if (res.status === 204) {
        commit("logout");
      }
      return res;
    },
    async getCurrentUser({ commit, dispatch }) {
      const res = await axiosIns.get("current");
      if (res.status === 200) {
        commit("login", res.data);
        await dispatch("fetchAssignmentSubjects");

        const navMenuItems = await dispatch("generateNavMenuItems");
        commit("setNavMenuItems", navMenuItems);
      }
    },
    async fetchAssignmentSubjects({ commit }) {
      try {

        const subjectsResponse = await axiosIns.get("subject_ddl/");
        const subjects = subjectsResponse.data;
        
        const assignmentChildren = subjects.map((subject) => ({
          title: subject.name,
          route: {
            name: "Assignment",   
            query: { id: subject.id }, 
          },
          icon: subject.icon || "BarChart2Icon",
        }));
        commit("setAssignmentChildren", assignmentChildren);

        const lectureChildren = subjects.map((subject) => ({
          title: subject.name,
          route: {
            name: "Lecture",   
            query: { id: subject.id }, 
          },
          icon: subject.icon || "BarChart2Icon",
        }));
        commit("setLectureChildren", lectureChildren);
      } catch (error) {
        console.error("Error fetching subjects:", error);
        throw error; 
      }
    },
    generateNavMenuItems({ state }) {
      const user = state.user;

      if (!user) {
        return [];
      }

      let navMenuItems = [];

      if (user.user_type === "student") {
        navMenuItems = [
          {
            title: "Dashboard",
            route: "Dashboard",
            icon: "MonitorIcon",
          },
          {
            title: "Subjects",
            route: "Subject",
            icon: "BookIcon",
          },
          
          {
            title: "Lectures",
            route: "Lecture",
            icon: "ClipboardIcon",
            children: state.lectureChildren, // Assign assignment children here
          },
          {
            title: "Assignments",
            route: "Assignment",
            icon: "ClipboardIcon",
            children: state.assignmentChildren, // Assign assignment children here
          },
          // {
          //   title: "TimeTable",
          //   route: "TimeTable",
          //   icon: "CalendarIcon",
          // },
          // {
          //   title: "Help",
          //   route: "Help",
          //   icon: "HelpCircleIcon",
          // },
        ];
      } else if (user.user_type === "teacher") {
        navMenuItems = [
          {
            title: "Dashboard",
            route: "InstructorDashboard",
            icon: "MonitorIcon",
          },
          // {
          //   title: "Classes",
          //   route: "InstructorClasses",
          //   icon: "MonitorIcon",
          // },
          {
            title: "Lectures",
            route: "InstructorLecture",
            icon: "ClipboardIcon",
            props: true,
          },
          {
            title: "Assignments",
            route: "InstructorAssignment",
            icon: "ClipboardIcon",
          },
          {
            title: "Student",
            route: "InstructorStudent",
            icon: "UsersIcon",
          },
          // {
          //   title: "Help",
          //   route: "Help",
          //   icon: "HelpCircleIcon",
          // },
        ];
      } else if (user.user_type === "institute_admin") {
        navMenuItems = [
          {
            title: "Dashboard",
            route: "UniHeadDashboard",
            icon: "MonitorIcon",
          },
          {
            title: "Department",
            route: "UniHeadDepartment",
            icon: "BookOpenIcon",
            children: [
              {
                title: "Department",
                route: "UniHeadDepartment",
                icon: "BarChart2Icon",
              },
              {
                title: "Teacher in Department",
                route: "UniHeadTeacher",
                icon: "BarChart2Icon",
              },
              // {
              //   title: "Teacher as Head",
              //   route: "UniHeadTeacherAsHead",
              //   icon: "BarChart2Icon",
              // },
              {
                title: "Classes/Programs",
                route: "UniHeadClassToDepartment",
                icon: "BarChart2Icon",
              },
            ],
          },
          {
            title: "Classes/Programs",
            route: "UniHeadClass",
            icon: "BookOpenIcon",
            children: [
              {
                title: "Classes/Programs",
                route: "UniHeadClass",
                icon: "BarChart2Icon",
              },
              {
                title: "Subject to a Class",
                route: "UniHeadSubjectToClass",
                icon: "BarChart2Icon",
              },
              {
                title: "Teacher to a Class",
                route: "UniHeadTeacherToClass",
                icon: "BarChart2Icon",
              },
              {
                title: "Student to a Class",
                route: "UniHeadStudentToClass",
                icon: "BarChart2Icon",
              },
              // {
              //   title: "View Stats",
              //   route: "UniHeadAllFunctionality",
              //   icon: "BarChart2Icon",
              // },
            ],
          },
          // {
          //   title: "License",
          //   route: "UniHeadLicense",
          //   icon: "MonitorIcon",
          // },
          // {
          //   title: "My Subscription",
          //   route: "UniHeadSubscription",
          //   icon: "MonitorIcon",
          // },
          // {
          //   title: "Payment",
          //   route: "UniHeadPayment",
          //   icon: "MonitorIcon",
          // },
          
        ];
      } else if (user.user_type === "city_admin" || user.user_type === "country_admin" || user.user_type === "super_admin") {
        navMenuItems = [
          {
            title: "Dashboard",
            route: "cityHeadDashboard",
            icon: "ColumnsIcon",
          },
          {
            title: "School",
            route: "cityHeadSchool",
            icon: "MonitorIcon",
          },
          {
            title: "College",
            route: "cityHeadCollege",
            icon: "ClipboardIcon",
          },
          {
            title: "University",
            route: "cityHeadUniversity",
            icon: "FileTextIcon",
          },
          // {
          //   title: "Invoice",
          //   route: "UniHeadInvoice",
          //   icon: "MonitorIcon",
          // },
          // {
          //   title: "Help",
          //   route: "Help",
          //   icon: "HelpCircleIcon",
          // },
          {
            title: "User",
            route: "User",
            icon: "UsersIcon",
          },
        ];
      }
      return navMenuItems;
    },  


    // User CRUD Functions
    async getUsers({ commit }, { pageNumber }) {
      let url = `users/?page=${pageNumber}`;      
      const res = await axiosIns.get(url);
      return res;
    },
    
    async getUser({ commit }, { pk }) {
      const res = await axiosIns.get(`users/${pk}/`);
      return res;
    },
    async createUser({ commit }, payload) {
      const res = await axiosIns.post("users/", payload);
      return res;
    },
    async updateUser({ commit }, { payload, pk }) {
      const res = await axiosIns.patch(`users/${pk}/`, payload);
      return res;
    },
    async deleteUser({ commit }, { pk }) {
      const res = await axiosIns.delete(`users/${pk}/`);
      return res;
    },


    // School CRUD Functions
    async getSchools({ commit }, { pageNumber, institute_type, name }) {
      let url = `institute/?page=${pageNumber}`;
      if (institute_type) {
        url += `&institute_type=${institute_type}`;
      }
      if (name) {
        url += `&name=${name}`;
      }
      
      const res = await axiosIns.get(url);
      return res;
    },
    
    async getSchoolDropDown() {
      let url = `institute_ddl/`;
      const res = await axiosIns.get(url);
      return res;
    },
    async getSchool({ commit }, { pk }) {
      const res = await axiosIns.get(`institute/${pk}/`);
      return res;
    },
    async createSchool({ commit }, payload) {
      const res = await axiosIns.post("institute/", payload);
      return res;
    },
    async updateSchool({ commit }, { payload, pk }) {
      const res = await axiosIns.patch(`institute/${pk}/`, payload);
      return res;
    },
    async deleteSchool({ commit }, { pk }) {
      const res = await axiosIns.delete(`institute/${pk}/`);
      return res;
    },


    // Department CRUD Functions
    async getDepartments({ commit }, { pageNumber, institute, name }) {
      let url = `department/?page=${pageNumber}`;
      if (institute) {
        url += `&institute=${institute}`;
      }
      if (name) {
        url += `&name=${name}`;
      }
      
      const res = await axiosIns.get(url);
      return res;
    },
    
    async getDepartmentDropDown() {
      let url = `department_ddl/`;
      const res = await axiosIns.get(url);
      return res;
    },
    async getDepartment({ commit }, { pk }) {
      const res = await axiosIns.get(`department/${pk}/`);
      return res;
    },
    async createDepartment({ commit }, payload) {
      const res = await axiosIns.post("department/", payload);
      return res;
    },
    async updateDepartment({ commit }, { payload, pk }) {
      const res = await axiosIns.patch(`department/${pk}/`, payload);
      return res;
    },
    async deleteDepartment({ commit }, { pk }) {
      const res = await axiosIns.delete(`department/${pk}/`);
      return res;
    },


    // Classes CRUD Functions
    async getClasses({ commit }, { pageNumber, name }) {
      let url = `classes/?page=${pageNumber}`;
      if (name) {
        url += `&name=${name}`;
      }
      
      const res = await axiosIns.get(url);
      return res;
    },
    
    async getClassDropDown() {
      let url = `classes_ddl/`;
      const res = await axiosIns.get(url);
      return res;
    },
    async getClass({ commit }, { pk }) {
      const res = await axiosIns.get(`classes/${pk}/`);
      return res;
    },
    async createClass({ commit }, payload) {
      const res = await axiosIns.post("classes/", payload);
      return res;
    },
    async updateClass({ commit }, { payload, pk }) {
      const res = await axiosIns.patch(`classes/${pk}/`, payload);
      return res;
    },
    async deleteClass({ commit }, { pk }) {
      const res = await axiosIns.delete(`classes/${pk}/`);
      return res;
    },



    // Subect CRUD Functions
    async getSubjects({ commit }, { pageNumber, name }) {
      let url = `subject/?page=${pageNumber}`;
      if (name) {
        url += `&name=${name}`;
      }
      const res = await axiosIns.get(url);
      return res;
    },
    
    async getSubjectDropDown() {
      let url = `subject_ddl/`;
      const res = await axiosIns.get(url);
      return res;
    },
    async getSubject({ commit }, { pk }) {
      const res = await axiosIns.get(`subject/${pk}/`);
      return res;
    },
    async createSubject({ commit }, payload) {
      const res = await axiosIns.post("subject/", payload);
      return res;
    },
    async updateSubject({ commit }, { payload, pk }) {
      const res = await axiosIns.patch(`subject/${pk}/`, payload);
      return res;
    },
    async deleteSubject({ commit }, { pk }) {
      const res = await axiosIns.delete(`subject/${pk}/`);
      return res;
    },


    // Student CRUD Functions
    async getStudents({ commit }, { pageNumber, name }) {
      let url = `student/?page=${pageNumber}`;
      if (name) {
        url += `&name=${name}`;
      }
      const res = await axiosIns.get(url);
      return res;
    },
    
    async getStudentsDropDown() {
      let url = `student/`;
      const res = await axiosIns.get(url);
      return res;
    },
    async getStudent({ commit }, { pk }) {
      const res = await axiosIns.get(`student/${pk}/`);
      return res;
    },
    async createStudent({ commit }, payload) {
      const res = await axiosIns.post("student/", payload);
      return res;
    },
    async updateStudent({ commit }, { payload, pk }) {
      const res = await axiosIns.patch(`student/${pk}/`, payload);
      return res;
    },
    async deleteStudent({ commit }, { pk }) {
      const res = await axiosIns.delete(`student/${pk}/`);
      return res;
    },



    // Teacher CRUD Functions
    async getTeachers({ commit }, { pageNumber, name }) {
      let url = `teacher/?page=${pageNumber}`;
      if (name) {
        url += `&name=${name}`;
      }
      const res = await axiosIns.get(url);
      return res;
    },
    
    async getTeachersDropDown() {
      let url = `teacher_ddl/`;
      const res = await axiosIns.get(url);
      return res;
    },
    async getTeacher({ commit }, { pk }) {
      const res = await axiosIns.get(`teacher/${pk}/`);
      return res;
    },
    async createTeacher({ commit }, payload) {
      const res = await axiosIns.post("teacher/", payload);
      return res;
    },
    async updateTeacher({ commit }, { payload, pk }) {
      const res = await axiosIns.patch(`teacher/${pk}/`, payload);
      return res;
    },
    async deleteTeacher({ commit }, { pk }) {
      const res = await axiosIns.delete(`teacher/${pk}/`);
      return res;
    },


    // Relation Teacher to Department CRUD Functions
    async getRelTeacherDepartments({ commit }, { pageNumber }) {
      let url = `rel_teacher_department/?page=${pageNumber}`;      
      const res = await axiosIns.get(url);
      return res;
    },
    
    async getRelTeacherDepartmentDropDown() {
      let url = `rel_teacher_department/`;
      const res = await axiosIns.get(url);
      return res;
    },
    async getRelTeacherDepartment({ commit }, { pk }) {
      const res = await axiosIns.get(`rel_teacher_department/${pk}/`);
      return res;
    },
    async createRelTeacherDepartment({ commit }, payload) {
      const res = await axiosIns.post("rel_teacher_department/", payload);
      return res;
    },
    async updateRelTeacherDepartment({ commit }, { payload, pk }) {
      const res = await axiosIns.patch(`rel_teacher_department/${pk}/`, payload);
      return res;
    },
    async deleteRelTeacherDepartment({ commit }, { pk }) {
      const res = await axiosIns.delete(`rel_teacher_department/${pk}/`);
      return res;
    },


    // Relation Classes to Department CRUD Functions
    async getRelClassesDepartments({ commit }, { pageNumber }) {
      let url = `rel_classes_department/?page=${pageNumber}`;      
      const res = await axiosIns.get(url);
      return res;
    },
    
    async getRelClassesDepartmentDropDown() {
      let url = `rel_classes_department/`;
      const res = await axiosIns.get(url);
      return res;
    },
    async getRelClassesDepartment({ commit }, { pk }) {
      const res = await axiosIns.get(`rel_classes_department/${pk}/`);
      return res;
    },
    async createRelClassesDepartment({ commit }, payload) {
      const res = await axiosIns.post("rel_classes_department/", payload);
      return res;
    },
    async updateRelClassesDepartment({ commit }, { payload, pk }) {
      const res = await axiosIns.patch(`rel_classes_department/${pk}/`, payload);
      return res;
    },
    async deleteRelClassesDepartment({ commit }, { pk }) {
      const res = await axiosIns.delete(`rel_classes_department/${pk}/`);
      return res;
    },


    // Assignment CRUD Functions
    async getAssignments({ commit }, { pageNumber, subject }) {
      let url = `assignment/?page=${pageNumber}`;      
      if (subject){
        url += `&subject=${subject}`;
      }
      const res = await axiosIns.get(url);
      return res;
    },
    
    async getAssignmentDropDown() {
      let url = `assignment/`;
      const res = await axiosIns.get(url);
      return res;
    },
    async getAssignment({ commit }, { pk }) {
      const res = await axiosIns.get(`assignment/${pk}/`);
      return res;
    },
    async createAssignment({ commit }, payload) {
      const res = await axiosIns.post("assignment/", payload);
      return res;
    },
    async updateAssignment({ commit }, { payload, pk }) {
      const res = await axiosIns.patch(`assignment/${pk}/`, payload);
      return res;
    },
    async deleteAssignment({ commit }, { pk }) {
      const res = await axiosIns.delete(`assignment/${pk}/`);
      return res;
    },

    async submitAssignment({ commit }, payload) {
      const res = await axiosIns.post("submit_assignment/", payload);
      return res;
    },


    // Lecture CRUD Functions
    async getLectures({ commit }, { pageNumber, subject }) {
      let url = `lecture/?page=${pageNumber}`;      
      if (subject){
        url += `&subject=${subject}`;
      }
      const res = await axiosIns.get(url);
      return res;
    },
    
    async getLectureDropDown() {
      let url = `lecture/`;
      const res = await axiosIns.get(url);
      return res;
    },
    async getLecture({ commit }, { pk }) {
      const res = await axiosIns.get(`lecture/${pk}/`);
      return res;
    },
    async createLecture({ commit }, payload) {
      const res = await axiosIns.post("lecture/", payload);
      return res;
    },
    async updateLecture({ commit }, { payload, pk }) {
      const res = await axiosIns.patch(`lecture/${pk}/`, payload);
      return res;
    },
    async deleteLecture({ commit }, { pk }) {
      const res = await axiosIns.delete(`lecture/${pk}/`);
      return res;
    },


    async getTeacherStats() {
      const res = await axiosIns.get(`teacher-dashboard/`);
      return res;
    },

    async getStudentStats() {
      const res = await axiosIns.get(`student-dashboard/`);
      return res;
    },

    async getInstituteStats() {
      const res = await axiosIns.get(`institute-admin-dashboard/`);
      return res;
    },
    



    /////////////////////////
    setAssignmentData({ commit }, assignmentData) {
      commit('setAssignmentData', assignmentData);
    },
    clearAssignmentData({ commit }) {
      commit('clearAssignmentData');
    },

    setLectureData({ commit }, lectureData) {
      commit('setLectureData', lectureData);
    },
    clearLectureData({ commit }) {
      commit('clearLectureData');
    },
    async getCountryDropDown() {
      let url = `country_ddl/`;
      const res = await axiosIns.get(url);
      return res;
    },
    async getCityDropDown() {
      let url = `city_ddl/`;
      const res = await axiosIns.get(url);
      return res;
    },
  },
};
