import Vue from "vue";
import VueRouter from "vue-router";
import store from '@/store';


Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes: [
    {
      path: "/login",
      name: "Login",
      component: () => import("@/views/auth/Login.vue"),
      meta: {
        layout: "full",
      },
    },
    {
      path: "/fogotPassword",
      name: "ForgotPassword",
      component: () => import("@/views/auth/ForgotPassword.vue"),
      meta: {
        layout: "full",
      },
    },
    {
      path: "/verification",
      name: "VerificationCode",
      component: () => import("@/views/auth/VerificationCode.vue"),
      meta: {
        layout: "full",
      },
    },
    {
      path: "/newPassword",
      name: "NewPassword",
      component: () => import("@/views/auth/NewPassword.vue"),
      meta: {
        layout: "full",
      },
    },
    {
      path: "/signup",
      name: "Signup",
      component: () => import("@/views/auth/Signup.vue"),
      meta: {
        layout: "full",
      },
    },
    {
      path: "/signupConfirmation",
      name: "SignupConfirmation",
      component: () => import("@/views/auth/SignupConfirmation.vue"),
      meta: {
        layout: "full",
      },
    },

    {
      path: "/user",
      name: "User",
      component: () => import("@/views/user/User.vue"),
      meta: {
        pageTitle: "User",
        breadcrumb: [
          {
            text: "User",
            active: true,
          },
        ],
        requiresAuth: true,
      },
    },

    {
      path: "/dashboard",
      name: "Dashboard",
      component: () => import("@/views/student/dashboard/Dashboard.vue"),
      meta: {
        pageTitle: "Dashboard",
        breadcrumb: [
          {
            text: "Dashboard",
            active: true,
          },
        ],
        requiresAuth: true,
      },
    },

    {
      path: "/subject",
      name: "Subject",
      component: () => import("@/views/student/subject/Subject.vue"),
      meta: {
        pageTitle: "Subjects",
        breadcrumb: [
          {
            text: "Subjects",
            active: true,
          },
        ],
        requiresAuth: true,
      },
    },
    {
      path: "/lecture",
      name: "Lecture",
      component: () => import("@/views/student/lecture/Lecture.vue"),
      meta: {
        pageTitle: "Lectures",
        breadcrumb: [
          {
            text: "Lectures",
            active: true,
          },
        ],
        requiresAuth: true,
      },
    },
    {
      path: "/startLecture",
      name: "StartLecture",
      component: () => import("@/views/student/lecture/StartLecture.vue"),
      meta: {
        pageTitle: "StartLecture",
        breadcrumb: [
          {
            text: "StartLecture",
            active: true,
          },
        ],
        requiresAuth: true,
      },
    },
    {
      path: "/lectureDescription",
      name: "LectureDescription",
      component: () => import("@/views/student/lecture/LectureDescription.vue"),
      meta: {
        pageTitle: "Lectures Description",
        breadcrumb: [
          {
            text: "Lectures Description",
            active: true,
          },
        ],
        requiresAuth: true,
      },
    },
    {
      path: "/lectureStats",
      name: "LectureStats",
      component: () => import("@/views/student/lecture/LectureStats.vue"),
      meta: {
        pageTitle: "Lectures Statistics",
        breadcrumb: [
          {
            text: "Lectures Statistics",
            active: true,
          },
        ],
        requiresAuth: true,
      },
    },
    {
      path: "/oxarDescription",
      name: "OxarDescription",
      component: () => import("@/views/student/lecture/OxarDescription.vue"),
      meta: {
        pageTitle: "Lectures",
        breadcrumb: [
          {
            text: "Lectures",
            active: true,
          },
        ],
        requiresAuth: true,
      },
    },
    {
      path: "/coding",
      name: "Coding",
      component: () => import("@/views/student/lecture/Lecture.vue"),
      meta: {
        pageTitle: "Coding",
        breadcrumb: [
          {
            text: "coding",
            active: true,
          },
        ],
        requiresAuth: true,
      },
    },

    {
      path: "/assignment",
      name: "Assignment",
      props: true,
      component: () => import("@/views/student/assignment/Assignment.vue"),
      meta: {
        pageTitle: "Assignment",
        breadcrumb: [
          {
            text: "Assignment",
            active: true,
          },
        ],
        requiresAuth: true,
      },
    },
    {
      path: "/time-table",
      name: "TimeTable",
      component: () =>
        import("@/views/student/timeTable/calendar/Calendar.vue"),
      meta: {
        pageTitle: "TimeTable",
        breadcrumb: [
          {
            text: "TimeTable",
            active: true,
          },
        ],
        requiresAuth: true,
      },
    },

    {
      path: "/help",
      name: "Help",
      component: () => import("@/views/student/help/Help.vue"),
      meta: {
        pageTitle: "Help",
        breadcrumb: [
          {
            text: "help",
            active: true,
          },
        ],
        requiresAuth: true,
      },
    },

    {
      path: "/instructorDashboard",
      name: "InstructorDashboard",
      component: () => import("@/views/instructor/dashboard/Dashboard.vue"),
      meta: {
        pageTitle: "Dashboard",
        breadcrumb: [
          {
            text: "Dashboard",
            active: true,
          },
        ],
        requiresAuth: true,
      },
    },
    {
      path: "/instructorAssignment",
      name: "InstructorAssignment",
      component: () => import("@/views/instructor/assignment/Assignment.vue"),
      meta: {
        pageTitle: "Assignment",
        breadcrumb: [
          {
            text: "Assignment",
            active: true,
          },
        ],
        requiresAuth: true,
      },
    },

    {
      path: "/instructorAssignmentCreate",
      name: "InstructorAssignmentCreate",
      props: true,
      component: () =>
        import("@/views/instructor/assignment/AssignmentCreate.vue"),
      meta: {
        pageTitle: "Assignment / Create Assignmnet",
        breadcrumb: [
          {
            text: "Assignments",
            active: true,
          },
        ],
        requiresAuth: true,
        
      },
    },

    {
      path: "/instructorLecture",
      name: "InstructorLecture",
      component: () => import("@/views/instructor/lecture/Lecture.vue"),
      meta: {
        pageTitle: "Lectures",
        breadcrumb: [
          {
            text: "Lectures",
            active: true,
          },
        ],
        requiresAuth: true,
      },
    },

    {
      path: "/instructorLectureCreate",
      name: "InstructorLectureCreate",
      component: () => import("@/views/instructor/lecture/LectureCreate.vue"),
      meta: {
        pageTitle: "Lectures / Create Lecture ",
        breadcrumb: [
          {
            text: "Lectures",
            active: true,
          },
        ],
        requiresAuth: true,
      },
    },

    {
      path: "/instructorLectureUpdate",
      name: "InstructorLectureUpdate",
      component: () => import("@/views/instructor/lecture/LectureUpdate.vue"),
      meta: {
        pageTitle: "Lectures / Create Update ",
        breadcrumb: [
          {
            text: "Lectures",
            active: true,
          },
        ],
        requiresAuth: true,
      },
    },

    {
      path: "/instructorLectureCreateTitle",
      name: "InstructorLectureCreateTitle",
      component: () =>
        import("@/views/instructor/lecture/LectureCreateTitle.vue"),
      meta: {
        pageTitle: "Lectures / Create Lecture / Lecture tiitle",
        breadcrumb: [
          {
            text: "Lectures",
            active: true,
          },
        ],
        requiresAuth: true,
      },
    },

    {
      path: "/instructorClasses",
      name: "InstructorClasses",
      component: () => import("@/views/instructor/classes/Class.vue"),
      meta: {
        pageTitle: "Classes",
        breadcrumb: [
          {
            text: "Classes",
            active: true,
          },
        ],
        requiresAuth: true,
      },
    },

    {
      path: "/instructorClassesDetail",
      name: "InstructorClassesDetail",
      component: () => import("@/views/instructor/classes/ClassDetail.vue"),
      meta: {
        pageTitle: "Classes / Classes Details",
        breadcrumb: [
          {
            text: "Classes / Classes Details",
            active: true,
          },
        ],
        requiresAuth: true,        
      },
    },

    {
      path: "/instructorSubjectDetail",
      name: "InstructorSubjectDetail",
      component: () => import("@/views/instructor/classes/SubjectDetail.vue"),
      meta: {
        pageTitle: "Classes / Classes Details / Subject Details",
        breadcrumb: [
          {
            text: "Subject Details",
            active: true,
          },
        ],
        requiresAuth: true,
      },
    },

    {
      path: "/instructorAssignmentDetail",
      name: "InstructorAssignmentDetail",
      component: () =>
        import("@/views/instructor/classes/AssignmentDetail.vue"),
      meta: {
        pageTitle: "Classes / Classes Details / Subject Details",
        breadcrumb: [
          {
            text: "Assignment Detail",
            active: true,
          },
        ],
        requiresAuth: true,
      },
    },

    {
      path: "/instructorStudent",
      name: "InstructorStudent",
      component: () => import("@/views/instructor/student/Student.vue"),
      meta: {
        pageTitle: "Student",
        breadcrumb: [
          {
            text: "Student",
            active: true,
          },
        ],
        requiresAuth: true,
      },
    },

    {
      path: "/instructorStudentCreate",
      name: "InstructorStudentCreate",
      component: () => import("@/views/instructor/student/StudentCreate.vue"),
      meta: {
        pageTitle: "Student / Create Student ",
        breadcrumb: [
          {
            text: "Student",
            active: true,
          },
        ],
        requiresAuth: true,
      },
    },

    {
      path: "/universityHeadDashboard",
      name: "UniHeadDashboard",
      component: () => import("@/views/uniHead/dashboard/Dashboard.vue"),
      meta: {
        pageTitle: "Dashboard",
        breadcrumb: [
          {
            text: "Dashboard",
            active: true,
          },
        ],
        requiresAuth: true,
      },
    },

    {
      path: "/universityHeadDepartment",
      name: "UniHeadDepartment",
      component: () => import("@/views/uniHead/department/Department.vue"),
      meta: {
        pageTitle: "Department",
        breadcrumb: [
          {
            text: "Department",
            active: true,
          },
        ],
        requiresAuth: true,
      },
    },

    {
      path: "/universityHeadTeacher",
      name: "UniHeadTeacher",
      component: () => import("@/views/uniHead/department/DepartmentTeacher.vue"),
      meta: {
        pageTitle: "Department / Teachers in Department",
        breadcrumb: [
          {
            text: "Department",
            active: true,
          },
        ],
        requiresAuth: true,

      },
    },

    {
      path: "/universityHeadTeacherAsHead",
      name: "UniHeadTeacherAsHead",
      component: () =>
        import("@/views/uniHead/department/DepartmentTeacherAsHead.vue"),
      meta: {
        pageTitle: "Department / Teacher as Head of department",
        breadcrumb: [
          {
            text: "Department",
            active: true,
          },
        ],
        requiresAuth: true,

      },
    },

    {
      path: "/universityHeadClassProgramToDepartment",
      name: "UniHeadClassToDepartment",
      component: () =>
        import("@/views/uniHead/department/DepartmentClassProgram.vue"),
      meta: {
        pageTitle: "Department / Classes/programs to a department",
        breadcrumb: [
          {
            text: "Department",
            active: true,
          },
        ],
        requiresAuth: true,

      },
    },

    {
      path: "/universityHeadClasses",
      name: "UniHeadClass",
      component: () => import("@/views/uniHead/classProgram/ClassProgram.vue"),
      meta: {
        pageTitle: "Classses ",
        breadcrumb: [
          {
            text: "Classses",
            active: true,
          },
        ],
        requiresAuth: true,

      },
    },

    {
      path: "/universityHeadSubjectToClasses",
      name: "UniHeadSubjectToClass",
      component: () =>
        import("@/views/uniHead/classProgram/SubjectToClassProgram.vue"),
      meta: {
        pageTitle: "Classes / Subjects to a Class/Program ",
        breadcrumb: [
          {
            text: "Classses",
            active: true,
          },
        ],
        requiresAuth: true,

      },
    },

    {
      path: "/universityHeadTeacherToClasses",
      name: "UniHeadTeacherToClass",
      component: () =>
        import("@/views/uniHead/classProgram/TeacherToClassProgram.vue"),
      meta: {
        pageTitle: "Classes / Teachers to a Class/Program ",
        breadcrumb: [
          {
            text: "Classses",
            active: true,
          },
        ],
        requiresAuth: true,

      },
    },

    {
      path: "/universityHeadStudentToClasses",
      name: "UniHeadStudentToClass",
      component: () =>
        import("@/views/uniHead/classProgram/StudentToClassProgram.vue"),
      meta: {
        pageTitle: "Classes / Students to a Class/Program ",
        breadcrumb: [
          {
            text: "Classses",
            active: true,
          },
        ],
        requiresAuth: true,

      },
    },

    {
      path: "/universityHeadAllFunctionality",
      name: "UniHeadAllFunctionality",
      component: () =>
        import("@/views/uniHead/classProgram/AllFunctionality.vue"),
      meta: {
        pageTitle: "View Stats",
        breadcrumb: [
          {
            text: "View Stats",
            active: true,
          },
        ],
        requiresAuth: true,

      },
    },

    {
      path: "/universityHeadLicense",
      name: "UniHeadLicense",
      component: () => import("@/views/uniHead/license/License.vue"),
      meta: {
        pageTitle: "License",
        breadcrumb: [
          {
            text: "License",
            active: true,
          },
        ],
        requiresAuth: true,

      },
    },

    {
      path: "/universityHeadSubscription",
      name: "UniHeadSubscription",
      component: () => import("@/views/uniHead/subscription/Subscription.vue"),
      meta: {
        pageTitle: "Subscription",
        breadcrumb: [
          {
            text: "Subscription",
            active: true,
          },
        ],
        requiresAuth: true,

      },
    },

    {
      path: "/universityHeadPayment",
      name: "UniHeadPayment",
      component: () => import("@/views/uniHead/payment/Payment.vue"),
      meta: {
        pageTitle: "Payment",
        breadcrumb: [
          {
            text: "Payment",
            active: true,
          },
        ],
        requiresAuth: true,

      },
    },

    {
      path: "/universityHeadInvoice",
      name: "UniHeadInvoice",
      component: () => import("@/views/uniHead/invoice/Invoice.vue"),
      meta: {
        pageTitle: "Invoice",
        breadcrumb: [
          {
            text: "Invoice",
            active: true,
          },
        ],
        requiresAuth: true,

      },
    },

    {
      path: "/cityHeadDashboard",
      name: "cityHeadDashboard",
      component: () => import("@/views/cityHead/dashboard/Dashboard.vue"),
      meta: {
        pageTitle: "Dashboard",
        breadcrumb: [
          {
            text: "Dashboard",
            active: true,
          },
        ],
        requiresAuth: true,
      },
    },

    {
      path: "/cityHeadSchool",
      name: "cityHeadSchool",
      component: () => import("@/views/cityHead/school/School.vue"),
      meta: {
        pageTitle: "School",
        breadcrumb: [
          {
            text: "School",
            active: true,
          },
        ],
        requiresAuth: true,
      },
    },

    {
      path: "/cityHeadCollege",
      name: "cityHeadCollege",
      component: () => import("@/views/cityHead/college/College.vue"),
      meta: {
        pageTitle: "College",
        breadcrumb: [
          {
            text: "College",
            active: true,
          },
        ],
        requiresAuth: true,
      },
    },

    {
      path: "/cityHeadUniversity",
      name: "cityHeadUniversity",
      component: () => import("@/views/cityHead/university/University.vue"),
      meta: {
        pageTitle: "University",
        breadcrumb: [
          {
            text: "University",
            active: true,
          },
        ],
        requiresAuth: true,
      },
    },

    {
      path: "/profile",
      name: "Profile",
      component: () => import("@/views/profile/Profile.vue"),
      meta: {
        pageTitle: "Profile",
        breadcrumb: [
          {
            text: "Profile",
            active: true,
          },
        ],
        requiresAuth: true,
      },
    },
    {
      path: "/editProfile",
      name: "EditProfile",
      component: () => import("@/views/profile/EditProfile.vue"),
      meta: {
        pageTitle: "EditProfile",
        breadcrumb: [
          {
            text: "EditProfile",
            active: true,
          },
        ],
        requiresAuth: true,
      },
    },

    {
      path: "*",
      redirect: "/login",
    },
  ],
});

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById("loading-bg");
  if (appLoading) {
    appLoading.style.display = "none";
  }
});

export default router;
